import { useMemo } from "react";
import { getQueryState } from "lib/common";
import { useQuery } from "react-query";
import { useMsal } from "@azure/msal-react";
import { IUseContextQueryProps, IUseContextQueryResult } from "./interfaces";
import { IContext } from ".";
import { usePermissionContext } from "../use-permission-context";

/**
 * Retrieves an item with context type and context uuid headers.
 * @returns The state, data or error associated with the query.
 */
export function useContextQuery<TRequest, TResponse>({
  enabled,
  permission,
  queryKey,
  queryFn,
  requestWithContext,
  appId,
  studySiteId,
  subgroupId,
  studyId,
  cacheTime,
  staleTime
}: IUseContextQueryProps<TRequest, TResponse>): IUseContextQueryResult<TResponse> {
  const { instance } = useMsal();
  const { permissionWithContext } = usePermissionContext({ permission, appId, studySiteId, subgroupId, studyId });

  // query is only executed if a matching permission context is found
  const _enabled = enabled && !!permissionWithContext;
  const context: IContext = {
    contextType: permissionWithContext?.context_type,
    contextUuid: permissionWithContext?.context_uuid
  };

  // apply context to request
  const request = requestWithContext(context);

  // apply context to query key array and ensure values are distinct
  const _queryKey = useMemo(() => Array.from(
    Array.isArray(queryKey) ?
      new Set(
        [
          ...queryKey,
          appId,
          context?.contextType,
          context?.contextUuid
        ]
      ) :
      new Set(
        [
          queryKey,
          appId,
          context?.contextType,
          context?.contextUuid
        ]
      )
  ), [queryKey, appId, context?.contextType, context?.contextUuid]);

  const query = useQuery(
    _queryKey,
    async () => await queryFn(instance, request),
    {
      enabled: _enabled,
      cacheTime,
      staleTime
    }
  );

  return {
    state: getQueryState(query.status),
    data: query.data,
    error: query.error,
    hasPermissionContext: !!permissionWithContext,
    refetch: query.refetch
  };
}
